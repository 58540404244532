import PropTypes from 'prop-types'
import { isNull, isUndefined } from 'underscore'
import s from './SeoText.module.css'

function SeoText({ text }) {
  if (isUndefined(text) || isNull(text) || text === '') {
    return <></>
  }

  return (
    <div
      className={s.container}
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  )
}

SeoText.propTypes = {
  text: PropTypes.string,
}

SeoText.defaultProps = {
  text: undefined,
}

export default SeoText
