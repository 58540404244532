import PropTypes from 'prop-types'
import { isNull, isUndefined } from 'underscore'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import s from './SeoFaqs.module.css'

function SeoFaqs({ faqs }) {
  if (isUndefined(faqs) || isNull(faqs) || faqs.length === 0) {
    return <></>
  }

  return (
    <div className={s.container}>
      <h2>FAQs</h2>
      <Accordion
        allowZeroExpanded={true}
        allowMultipleExpanded={false}
        className={s.accordion}
      >
        {faqs.map(({ question, answer }, i) => (
          <AccordionItem key={i} className={s.accordion__item}>
            <AccordionItemHeading>
              <AccordionItemButton className={s.accordion__button}>
                {question}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.accordion__panel}>
              <div dangerouslySetInnerHTML={{ __html: answer }}></div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

SeoFaqs.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ),
}

SeoFaqs.defaultProps = {
  faqs: [],
}

export default SeoFaqs
